var Wrede = Wrede || {};
Wrede = function () {
  "use strict";

  this.init();
};
(function ($, window) {
  "use strict";
  var backgroundBreakpoint = 0;

  Wrede.prototype.init = function () {
    var me = this;
    me.initMobileSettings();
    me.initControls();
    me.initMenu();
    me.initReveal();
    me.initHeroAnimation();
    me.initLazyLoad();
    me.initRealtorModal();
    me.initPageModal();
    me.initCommon();
    me.initAccordions();
    me.fixWrapper();
    setTimeout(function () {
      me.fixWrapper();
    }, 2000);
    me.addToCalendar();
    me.initNewsletter();
    me.initSpekulantregister();
    me.initObjectFit();
    me.initFormValidation();
    me.initCustomerpages();
    me.initObjectsCarousel();
    me.initCardsCarousel();
    me.initLogotype();
    me.initFasadHero();
    me.initListCarousel();
    AOS.init();
  };

  if ($("#fasad-list-item-wrapper").length) {
    var muuriGrid = new Muuri("#fasad-list-item-wrapper", {});
    if (
      $("#fasad-list-item-wrapper").hasClass("underhand") ||
      $("#fasad-list-item-wrapper").hasClass("vaning") ||
      $("#fasad-list-item-wrapper").hasClass("villa") ||
      $("#fasad-list-item-wrapper").hasClass("lant-sjostallen") ||
      $("#fasad-list-item-wrapper").hasClass("skane-gotland")
    ) {
      console.log("destroy muuri");
      muuriGrid.destroy();
    }
  }

  Wrede.prototype.initFasadHero = function () {
    var $fasad_hero = $(".fasad-hero");

    if ($fasad_hero.length) {
      var carousel = new Swiper($fasad_hero.find(".carousel").get(0), {
        roundLengths: true,
        effect: "fade",
        loop: true,
        followFinger: false,
        autoplay: {
          delay: 4000,
        },
        fadeEffect: {
          crossFade: true,
        },
        speed: 1500,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }
  };

  Wrede.prototype.sendMail = function (
    to,
    subject,
    message,
    type,
    address,
    checklistitemTitle
  ) {
    const data = {
      action: "send_mail",
      ajax_nonce: localizedVars.ajax_object.ajax_nonce,
      to: to,
      type: type,
      subject: subject,
      message: message,
      address: address,
      checklistitemTitle: checklistitemTitle,
    };

    $.post(localizedVars.ajax_object.ajax_url, data, function (response) {});
  };

  Wrede.prototype.getCustomerpagesData = function () {
    const $customerpagesData = $("#customerpages-data div");
    if ($customerpagesData.length) {
      const data = {
        address: $customerpagesData.data("address"),
        realtormail: $customerpagesData.data("realtormail"),
      };
      return data;
    }
    return false;
  };
  Wrede.prototype.initCustomerpages = function () {
    const me = this;
    const $customerpagesContainer = $("#customerpages-container");
    const $customerpagesloader = $("#customerpagesloader");

    if ($customerpagesContainer.length) {
      $customerpagesContainer.on("customerpages_bankidStatus", function (e) {
        if (e.detail.status === "INITIATED" || e.detail.status === "PENDING") {
          me.showResponse("Starta din BankID-app");
        } else if (
          e.detail.status === "COMPLETE" ||
          e.detail.status === "TIMEOUT"
        ) {
          me.hideResponse();
        }
      });
      $customerpagesContainer.on("customerpages_login", function (e) {
        $("html, body").scrollTop(0);
      });
      $customerpagesContainer.on("customerpages_notify", function (e) {
        if (e.detail.message) {
          var message = e.detail.message;
          console.log(e.detail);
          if (e.detail.type && e.detail.type == "upload") {
            message = "Nu är ditt dokument inskickat";
          } else if (e.detail.type && e.detail.type == "createPdf") {
            message = "Frågelistan är inskickad till din mäklare!";
          }
          me.showResponse(message);
        }
      });
      $customerpagesContainer.on("customerpages_createPdf", function (e) {
        if (e.detail.success == true) {
          const data = me.getCustomerpagesData();
          const subject = "Wredes kundportal";
          if (data) {
            me.sendMail(
              data.realtormail,
              subject + " " + data.address,
              "",
              "questionnairesent",
              data.address
            );
          }
          setTimeout(function () {
            $("body, html").animate({
              scrollTop: $customerpagesContainer[0].offsetTop + 500,
            });
          }, 400);
        }
      });
      $customerpagesContainer.on(
        "customerpages_submitToChecklistitem",
        function (e) {
          if (e.detail.success == true) {
            var checklistitemTitle = "";
            if (e.detail.title) {
              checklistitemTitle = e.detail.title;
            }
            const data = me.getCustomerpagesData();
            const subject = "Wredes kundportal";
            if (data) {
              me.sendMail(
                data.realtormail,
                subject + " " + data.address,
                "",
                e.detail.type,
                data.address,
                checklistitemTitle
              );
            }
          }
        }
      );
      $customerpagesContainer.on(
        "click",
        "a.questionnaire-button",
        function (e) {
          const $fields = $customerpagesContainer.find(".questionnaire-fields");
          e.preventDefault();
          if ($fields.hasClass("hidden")) {
            $fields.hide();
            $fields.removeClass("hidden");
          }
          $fields.slideToggle();
        }
      );
    }
    if ($customerpagesloader.length) {
      $customerpagesloader.detach().appendTo("body");
    }
  };
  Wrede.prototype.hideResponse = function () {
    var $response = $("#message-response");
    $response.find("span.content").html("");
    if (!$response.hasClass("hidden")) {
      $response.addClass("hidden");
    }
  };
  Wrede.prototype.showResponse = function (message) {
    var $response = $("#message-response");
    $response.find("span.content").html(message);
    if ($response.hasClass("hidden")) {
      $response.removeClass("hidden");
    }
    $response
      .find(".wrede__button.wrede__button--close")
      .on("click", function () {
        $response.addClass("hidden");
      });
  };
  Wrede.prototype.initFormValidation = function () {
    var me = this;

    //Validate contact form 7
    $(".wpcf7-form").on("keyup change", function () {
      if ($(".wpcf7-form input[name='required_fields']").length > 0) {
        var $required = $(".wpcf7-form input[name='required_fields']")
          .val()
          .split("|");
        me.validateForm(".wpcf7-form", $required, false);
      }
    });
    $('.wpcf7-form input[type="submit"]').on("click", function (e) {
      if ($(".wpcf7-form input[name='required_fields']").length > 0) {
        var $required = $(".wpcf7-form input[name='required_fields']")
          .val()
          .split("|");
        if (!me.validateForm(".wpcf7-form", $required, true)) {
          e.preventDefault();
        }
      }
    });
    //Validate interest/showing
    $(".basic-contact-form").on("change", function () {
      var $form = $(this).closest("form");
      if ($form.find("input[name='required_fields']").val()) {
        var $required = $form
          .find("input[name='required_fields']")
          .val()
          .split("|");
        me.validateForm($form, $required, false);
      }
    });
    $('.basic-contact-form input[type="submit"]').on("click", function (e) {
      var $form = $(this).closest("form");
      var $required = $form
        .find("input[name='required_fields']")
        .val()
        .split("|");
      if (!me.validateForm($form, $required, true)) {
        e.preventDefault();
      }
    });
  };
  Wrede.prototype.validateForm = function (
    form,
    requiredFields,
    checkForErrors
  ) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if (form instanceof jQuery) {
      var $form = form;
    } else {
      var $form = $(form);
    }
    var valid = true;
    var allChecked = true;
    if (!Array.isArray(requiredFields) || !$form.length) {
      return false;
    }
    if (requiredFields === 0) {
      return true;
    }
    $.each(requiredFields, function (key, value) {
      var $input = $form.find("input[name='" + value + "']");
      if (!$input.length) {
        return false;
      }
      $input.removeClass("checked");
      if (
        $input.attr("type") === "radio" ||
        $input.attr("type") === "checkbox"
      ) {
        //Only one input to be checked
        if ($input.length == 1) {
          if ($input.prop("checked")) {
            $input.addClass("checked");
            $input.removeClass("error");
          } else {
            if (checkForErrors) {
              $input.addClass("error");
              valid = false;
            }
          }
          //We have a multiple checkbox/radio to validate
        } else {
          var anyValid = false;
          $input.each(function () {
            if ($(this).prop("checked")) {
              anyValid = true;
            }
          });
          $input.each(function () {
            if (anyValid) {
              $(this).addClass("checked");
              $(this).removeClass("error");
            } else {
              if (checkForErrors) {
                $(this).addClass("error");
                valid = false;
              }
            }
          });
        }
      } else if ($input.attr("type") === "email") {
        if ($input.val() !== "" && emailReg.test($input.val())) {
          $input.addClass("checked");
          $input.removeClass("error");
        } else {
          if (checkForErrors) {
            $input.addClass("error");
            valid = false;
          }
        }
      } else {
        if ($input.val() !== "") {
          $input.addClass("checked");
          $input.removeClass("error");
        } else {
          if (checkForErrors) {
            $input.addClass("error");
            valid = false;
          }
        }
      }
    });
    $.each(requiredFields, function (key, value) {
      var $input = $form.find("input[name='" + value + "']");
      if (!$input.hasClass("checked")) {
        allChecked = false;
      }
    });
    if (allChecked === true) {
      $form.addClass("checked");
    } else {
      $form.removeClass("checked");
    }
    return valid;
  };
  Wrede.prototype.initForRent = function () {
    $(".object-info").on("click touchstart touchend", function () {
      $(".object-info").removeClass("hover");
      $(this).addClass("hover");
    });
  };
  Wrede.prototype.initObjectFit = function () {
    this.makeObjectFitSpecialForIE(".object-fit");
  };
  Wrede.prototype.makeObjectFitSpecialForIE = function ($imagesSelector) {
    if (this.detectIE()) {
      var $images = $($imagesSelector);
      $images.each(function () {
        var $img = $(this);
        var $imgContainer = $img.parent();
        var imgUrl = $img.data("lazy");
        if (!imgUrl) {
          imgUrl = $img.prop("src");
        }
        var position = $img.data("position");

        if (imgUrl) {
          $imgContainer
            .css("backgroundImage", "url(" + imgUrl + ")")
            .addClass("compat-object-fit");
          if (position) {
            $imgContainer.css("background-position", position);
          }
        }
      });
    }
  };
  Wrede.prototype.detectIE = function () {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    return false;
  };
  Wrede.prototype.initUnderhand = function () {
    var cookieName = "wredeUOCookieHide";
    var theCookie = document.cookie;
    var ind = theCookie.indexOf(cookieName + "=");
    if (ind < 0) {
      $(".underhand-overlay").removeClass("hidden");
    }
    $(".underhand-overlay .wrede__button--close").on(
      "click touchstart touchend",
      function () {
        $(".underhand-overlay").addClass("hidden");
        document.cookie = cookieName + "=1";
      }
    );
  };
  Wrede.prototype.initNewsletter = function () {
    var me = this;
    $(".open-newsletter-modal").on("click", function (e) {
      e.preventDefault();
      me.showModal("#newsletterModal");
    });
    $(".newsletter_form").on("submit", function (e) {
      var $accept = $(this).find("#newsletter_accept");
      $accept.on("change", function () {
        $accept.siblings(".checkmark").css("border-color", "#b2b2b2");
      });
      if (!$accept.prop("checked")) {
        e.preventDefault();
        $accept.siblings(".checkmark").css("border-color", "#ff0000");
      }
    });
  };
  Wrede.prototype.initSpekulantregister = function () {
    $(document)
      .find(".wpcf7-form")
      .find(".checkbox-wrapper")
      .find("input")
      .after('<span class="checkmark"></span>');
    $(document)
      .find(".wpcf7-form")
      .find(".checkbox-wrapper")
      .find("label")
      .unwrap()
      .unwrap()
      .unwrap();
    $(document)
      .find(".wpcf7-form")
      .not(".checkbox-wrapper")
      .find(".wpcf7-form-control-wrap")
      .find("input")
      .unwrap();
    $(document)
      .find(".wpcf7-form")
      .find(".checkbox-wrapper.col-wrap")
      .find("label")
      .wrap('<div class="col-6 col-md-3"></div>')
      .wrap('<span class="contact-form-list-item"></span>');
    var $objectType = $('.wpcf7-form input[name="object-type[]"]');
    var $areaWrapper = $(".wpcf7-form .area-wrapper");
    if ($objectType.length) {
      $objectType.on("change", function () {
        $areaWrapper.removeClass("hidden");
        $objectType.prop("checked", false);
        $(this).prop("checked", true);
        var val = $(this).val();
        if (val == "Skåne & Gotland" || val == "Lant- & sjöställen") {
          $areaWrapper.addClass("hidden");
        }
      });
    }
  };
  Wrede.prototype.addToCalendar = function () {
    $(".showing-container").on("click", function () {
      $(".add-showing").slideUp();
      $(this).next().slideDown();
    });
    $(".add-showing").on("click", function () {
      var id = $(this).data("id");
      $("#showing_" + id).trigger("submit");
    });
  };
  Wrede.prototype.initCommon = function () {
    $(".button.show-more-newproduction").on(
      "click touchstart touchend",
      function () {
        if (!$(this).hasClass("revealed")) {
          $(this).addClass("hidden");
          $("article.newproduction.hidden").removeClass("hidden");
          muuriGrid.destroy();
          sr.delegate();
        }
      }
    );
  };
  Wrede.prototype.initAccordions = function () {
    const accordions = document.querySelectorAll(
      ".wrede__accordions__accordion"
    );

    const onClick = function (accordion) {
      accordion.addEventListener("click", function () {
        accordion.classList.toggle("expanded");
      });
    };

    accordions.forEach(function (accordion) {
      onClick(accordion);
    });
  };
  Wrede.prototype.initPageModal = function () {
    $(".open-page-modal").on("click", function (e) {
      e.preventDefault();
      var type = $(this).data("type");
      var page = $(this).data("page");
      if ($(this).hasClass("ctcc-more-info-link")) {
        type = "page";
        page = $(this).attr("href");
        if (page.substr(-1) === "/") {
          page = page.substr(0, page.length - 1);
        }
        page = page.substr(page.lastIndexOf("/") + 1, page.length);
      }
      var data = {
        action: "get_modal_content",
        ajax_nonce: localizedVars.ajax_object.ajax_nonce,
        type: type,
        page: page,
      };
      $.post(localizedVars.ajax_object.ajax_url, data, function (response) {
        if (response) {
          var jsonResponse = JSON.parse(response);
          if (jsonResponse.success === 1) {
            var $modal = $("#pageModal");
            if (type == "realtor") {
              $modal = $("#realtorInfo");
            }
            $modal.find(".modal-title").text(jsonResponse.message.title);
            $modal.find(".modal-image").html(jsonResponse.message.image);
            $modal.find(".modal-body").html(jsonResponse.message.content);
            $modal.modal("show");
          }
        }
      });
    });
  };
  Wrede.prototype.initRealtorModal = function () {
    $(".open-realtor-modal").on("click", function (e) {
      e.preventDefault();
    });
  };
  Wrede.prototype.initSingleFasadObject = function () {
    var me = this;

    // 9918 = production id with video that is too fast
    if (
      $("body").hasClass("postid-9918") ||
      $("body").hasClass("postid-10256") ||
      $("body").hasClass("postid-10255")
    ) {
      const video = document.querySelector("video");
      // Slow it
      if (typeof video !== "undefined") {
        $("body").addClass("video-fix");
        // wait till customer approves : video.playbackRate = 0.6;
      }
    }

    var $header = $("header");
    $(".hero-overlay > div.hero-meta").removeClass("hero-hidden");
    $(".hero-overlay > div.hero-arrow-wrapper").removeClass("hero-hidden");
    if ($("html").scrollTop() > 0) {
      $("main").removeClass("nomargin-top");
    }
    $(document).on("scroll", function () {
      var scrollTop = $(document).scrollTop();
      if ($("main").hasClass("nomargin-top") && scrollTop > 0) {
        $("main").removeClass("nomargin-top");
        $(".hero-arrow-wrapper .arrow").css("opacity", 0);
      } else if (scrollTop === 0) {
        $(".hero-arrow-wrapper .arrow").css("opacity", 1);
        $("main").addClass("nomargin-top");
      }
    });
    $(".hero .read-more").on("click", function () {
      $("html").animate(
        {
          scrollTop: $(".hero").outerHeight() * 0.8,
        },
        500
      );
    });
    $(".button.to-top").on("click touchstart touchend", function () {
      const $fasad_object_container = $(".fasad_object-container");
      if ($fasad_object_container.length) {
        $("body, html").animate(
          { scrollTop: $fasad_object_container.offset().top },
          "2500"
        );
      }
      me.fixWrapper();
    });
    $(".show_more_images").on("click", function (e) {
      e.preventDefault();
      var $fasad_object_container = $(".fasad_object-container");
      if (!$(this).hasClass("revealed")) {
        $(this).addClass("revealed");
        $fasad_object_container.addClass("revealed");
        const $imageContainers = $(".image-container.hidden");
        $(this).html($(this).data("title-revealed"));
        if ($imageContainers.length) {
          $.each($imageContainers, function () {
            $(this).addClass("revealed");
            $(this).removeClass("hidden");
            const $img = $(this).find("img");
            if ($img.length) {
              me.preloadImage($img[0]);
            }
          });
        }
      } else {
        const $imageContainers = $(".image-container.revealed");
        $(this).removeClass("revealed");
        $fasad_object_container.removeClass("revealed");
        $(this).html($(this).data("title-hidden"));
        if ($imageContainers.length) {
          $.each($imageContainers, function () {
            $(this).removeClass("revealed");
            $(this).addClass("hidden");
          });
        }
        if ($fasad_object_container.length) {
          $("body, html").animate(
            { scrollTop: $fasad_object_container.offset().top },
            "2500"
          );
        }
      }
    });
    $(".button.interest").on("click touchstart touchend", function () {
      me.showModal("#showingModal");
    });
    $(".floorplan-link").on("click touchstart touchend", function (e) {
      //e.preventDefault();
      me.showModal("#planModal");
    });
    $(".plotmap-link").on("click touchstart touchend", function () {
      me.showModal("#plotModal");
    });

    var moreImagesShown = false;

    $(".goto-images").on("click", function (e) {
      e.preventDefault();
      if (!$(this).hasClass("revealed")) {
        $("body, html").animate(
          {
            scrollTop:
              $(".object-images").offset().top - $("header").outerHeight(),
          },
          400
        );

        if (!moreImagesShown) {
          $(".show_more_images").trigger("click");
        }

        moreImagesShown = true;
      }
    });
    me.fixWrapper();
    //me.initMap();
  };
  Wrede.prototype.hideModal = function (selector) {
    var $modal = $(selector);
    if ($modal.length) {
      $modal.modal("hide");
    }
  };
  Wrede.prototype.showModal = function (selector) {
    var me = this;
    var $modal = $(selector);
    if ($modal.length) {
      $modal.modal("show");
      if (selector === "#planModal") {
        me.initPlanSlick();
      } else if (selector === "#plotModal") {
        me.initPlotSlick();
      }
    }
  };
  Wrede.prototype.initPlotSlick = function () {
    var me = this;
    var $slickPlots = $(".slick-plots");
    if ($slickPlots.length) {
      if (!$slickPlots.hasClass("slick-initialized")) {
        $slickPlots.slick({
          dots: true,
          appendArrows: ".slick-arrows.plots",
          appendDots: ".slick-dots-list.plots",
          prevArrow: '<span class="wrede__button wrede__button--prev"></span>',
          nextArrow: '<span class="wrede__button wrede__button--next"></span>',
        });
      } else {
        $slickPlots.slick("unslick");
        me.initPlotSlick();
      }
    }
  };
  Wrede.prototype.initPlan = function () {
    var me = this;
    var $slickPlans = $(".object-floorplan").find(".slick-plans");
    if ($slickPlans.length) {
      if (!$slickPlans.hasClass("slick-initialized")) {
        $slickPlans.slick({
          arrows: false,
          dots: true,
          appendDots: ".object-floorplan .slick-dots-list.plans",
          asNavFor: ".slick-plans-modal",
          focusOnSelect: true,
          autoplay: false,
          speed: 900,
          fade: true,
          autoplaySpeed: 1800,
        });
      }
    }
  };
  Wrede.prototype.initPlanSlick = function () {
    var me = this;
    var $slickPlans = $(".modal").find(".slick-plans");
    if ($slickPlans.length) {
      if (!$slickPlans.hasClass("slick-initialized")) {
        $slickPlans.slick({
          dots: true,
          appendArrows: ".modal .slick-arrows.plans",
          appendDots: ".modal .slick-dots-list.plans",
          prevArrow: '<span class="wrede__button wrede__button--prev"></span>',
          nextArrow: '<span class="wrede__button wrede__button--next"></span>',
          asNavFor: ".slick-plans-inline",
        });
      } else {
        $slickPlans.slick("unslick");
        me.initPlanSlick();
      }
    }
  };
  Wrede.prototype.fixWrapper = function () {
    var $wrapper = $(".wrapper");
    var $fasad_object_container = $(".fasad_object-container");
    var $wrapper_align = $(".wrapper-align");
    var height = 0;
    if ($fasad_object_container.length) {
      height = $fasad_object_container.outerHeight() - 115 + 300;
    }
    if ($wrapper_align.length) {
      var wrapper_top = $wrapper.offset().top;
      var wrapper_align_top = $wrapper_align.offset().top;
      var wrapper_align_height = $wrapper_align.outerHeight(true);
      height = wrapper_align_top + wrapper_align_height - wrapper_top;
    }
    $wrapper.css("height", height + "px");
  };
  /**
   * Scrollreveal functionality
   */
  Wrede.prototype.initReveal = function () {
    if (this.detectIE()) {
      $(".reveal").css("visibility", "visible");
    }
    var config = {
      distance: "15px",
      viewFactor: "0.3",
      duration: 1000,
      scale: 1,
      delay: 50,
    };
    var defaultDelay = 50;
    var delay = defaultDelay;

    //Init scrollreveal with default configuration
    window.sr = new ScrollReveal(config);
    //If revealwrapper exists on page, take data from this one (only one wrapper per page, special case if more wrappers)
    var $revealWrapper = $(".reveal-wrapper");
    if ($revealWrapper.length) {
      if (typeof $revealWrapper.data("rv-scale") !== "undefined") {
        config.scale = $revealWrapper.data("rv-scale");
      }
      if (typeof $revealWrapper.data("rv-duration") !== "undefined") {
        config.duration = $revealWrapper.data("rv-duration");
      }
      if (typeof $revealWrapper.data("rv-origin") !== "undefined") {
        config.origin = $revealWrapper.data("rv-origin");
      }
      if (typeof $revealWrapper.data("rv-distance") !== "undefined") {
        config.distance = $revealWrapper.data("rv-distance");
      }
      if (typeof $revealWrapper.data("rv-viewfactor") !== "undefined") {
        config.viewFactor = $revealWrapper.data("rv-viewfactor");
      }
      if (typeof $revealWrapper.data("rv-reset") !== "undefined") {
        config.reset = $revealWrapper.data("rv-reset");
      }
      if (typeof $revealWrapper.data("rv-delay") !== "undefined") {
        config.delay = $revealWrapper.data("rv-delay");
      }
      if (typeof $revealWrapper.data("rv-synched-delay") !== "undefined") {
        delay = $revealWrapper.data("rv-synched-delay");
      }
      config.container = $revealWrapper;
    }
    //Find reveal elements that are children of revealwrapper but not synched
    var $revealWithConfig = $(".reveal-wrapper .reveal").not(
      ".reveal-wrapper .synched"
    );

    //Find reveal elements that are not children of revealwrapper and not synched
    var $reveal = $(".reveal").not(".synched").not(".reveal-wrapper .reveal");

    //Find reveal elements that are children of revealwrapper and synched
    var $revealSynchedWithConfig = $(".reveal-wrapper .reveal.synched");

    //Find reveal elements that are not children of revealwrapper and synched
    var $revealSynched = $(".reveal.synched").not(
      ".reveal-wrapper .reveal.synched"
    );

    //Init reveal with config on reveal-wrapper -> .reveal
    if ($revealWithConfig.length) {
      sr.reveal($revealWithConfig, config);
    }
    //Init reveal without config on NOT reveal-wrapper -> .reveal
    if ($reveal.length) {
      sr.reveal($reveal);
    }

    //Init reveal with config and synch on reveal-wrapper -> .reveal.synched
    if ($revealSynchedWithConfig.length) {
      sr.reveal($revealSynchedWithConfig, config, delay);
    }
    //Init reveal without config and is synch on NOT reveal-wrapper -> .reveal.synched
    if ($revealSynched.length) {
      sr.reveal($revealSynched, defaultDelay);
    }
    var $revealWrapperAboutUs = $(".reveal-wrapper-about-us");
    if ($revealWrapperAboutUs.length) {
      sr.reveal(".reveal");
    }
  };
  Wrede.prototype.initHeroAnimation = function () {
    const $hero = $(".hero.animate");
    if ($hero.length) {
      const $img = $($hero.find("img"));
      if ($img.length) {
        if ($img[0].complete) {
          //Image already loaded (cache)
          $hero.addClass("in");
        }
        $img.on("load", function () {
          //wait for image load
          $hero.addClass("in");
        });
      }
      $(window).on("resize", function () {
        $hero.addClass("notransition");
        $hero.find("img").addClass("notransition");
        $hero.removeClass("in");
        setTimeout(function () {
          $hero.removeClass("notransition");
          $hero.find("img").removeClass("notransition");
          $hero.addClass("in");
        }, 500);
      });
    }
  };
  Wrede.prototype.initLazyLoad = function () {
    const me = this;
    const images = document.querySelectorAll(".lazy-image");
    if (!images.length) {
      return;
    }

    const config = {
      rootMargin: "-50px 0px -50px 0px", // determines when the observer has the element in "viewport"
      threshold: 0,
    };

    const observer = new IntersectionObserver(function (entries, self) {
      entries.forEach(function (entry) {
        var entryTarget = entry.target;

        if (entry.isIntersecting) {
          me.preloadImage(entryTarget);

          if (entryTarget.tagName == "VIDEO") {
            entryTarget.addEventListener("loadeddata", function () {
              if (entryTarget.readyState == 4) {
                entryTarget.classList.add("loaded");
              }
            });
          } else {
            entryTarget.addEventListener("load", function (entry) {
              entryTarget.classList.add("loaded");
            });
          }
          //stop watching
          self.unobserve(entryTarget);
        }
      });
    }, config);
    images.forEach(function (image) {
      observer.observe(image);
    });
  };
  Wrede.prototype.preloadImage = function (img) {
    const src = img.getAttribute("data-src");
    if (!src) {
      return;
    }
    img.src = src;

    const srcSet = img.getAttribute("data-srcset");
    if (!srcSet) {
      return;
    }
    img.srcset = srcSet;
  };
  Wrede.prototype.initFrontPage = function () {
    var $hero = $(".hero");
    $hero.on("click", function () {
      if (!$(".nav-wrapper").hasClass("open")) {
        $(".menu-button.open-menu").trigger("click");
      }
    });
    $(window).on("mousewheel", function () {
      if (!$(".nav-wrapper").hasClass("open")) {
        $(".menu-button.open-menu").trigger("click");
      }
    });
    var $slick_home = $(".slick-home");
    if ($slick_home.length) {
      $slick_home.on("init", function () {
        $slick_home.find(".slick-current img").addClass("active");
      });
      $slick_home.on(
        "afterChange",
        function (event, slick, currentSlide, nextSlide) {
          $slick_home.find("img").removeClass("active");
          $slick_home.find(".slick-current img").addClass("active");
        }
      );

      $slick_home.slick({
        lazyLoad: "ondemand",
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 29000,
        pauseOnFocus: false,
        pauseOnHover: false,
        fade: true,
        speed: 1500,
        cssEase: "linear",
      });
    }
  };

  Wrede.prototype.initMenu = function () {
    $(".ctcc-more-info-link").addClass("open-page-modal");
    var $brand = $(".brand");
    var $nav = $(".nav-wrapper");
    var $navprimary = $nav.find(".nav-primary").find("li");
    var $navinfo = $nav.find(".wrede-info-wrapper");
    var $menubuttonopen = $(".menu-button.open-menu");
    var $menubuttonclose = $(".menu-button.close-menu");
    var windowWidth = $(window).width();
    $menubuttonopen.on("click", function (e) {
      if (!$nav.hasClass("open")) {
        if (windowWidth < 768) {
          $brand.addClass("dark");
        }
        $(".menu-category-container").addClass("opacity-0");
        $nav.addClass("open");
        setTimeout(function () {
          $navprimary.addClass("notransition");
          $navinfo.addClass("notransition");
          $nav.css("overflow-y", "auto");
        }, 1800);
      } else {
        $nav.removeClass("open");
        $nav.css("overflow-y", "hidden");
        setTimeout(function () {
          $navprimary.removeClass("notransition");
          $navinfo.removeClass("notransition");
        }, 500);
      }
      e.preventDefault();
    });

    $menubuttonclose.on("click", function (e) {
      if (!$nav.hasClass("open")) {
        $nav.addClass("open");
        setTimeout(function () {
          $navprimary.addClass("notransition");
          $navinfo.addClass("notransition");
          $nav.css("overflow-y", "auto");
        }, 1800);
      } else {
        if (windowWidth < 768) {
          $brand.removeClass("dark");
        }
        $(".menu-category-container").removeClass("opacity-0");
        $nav.removeClass("open");
        $nav.css("overflow-y", "hidden");
        setTimeout(function () {
          $navprimary.removeClass("notransition");
          $navinfo.removeClass("notransition");
        }, 500);
      }
      e.preventDefault();
    });
  };
  Wrede.prototype.initMobileSettings = function () {
    var breakpoint = 768;
    if ($(window).width() <= breakpoint) {
      $("body").addClass("mobile");
    } else {
      $("body").removeClass("mobile");
    }
  };

  Wrede.prototype.initControls = function () {
    var me = this;
    var $currentScrollTop = $(window).scrollTop();
    $(window).on("resize", function () {
      me.onResize();
      me.onScroll();
    });
    me.onResize();

    $(window).on("scroll", function () {
      me.onScroll();
    });

    if ($currentScrollTop > 0) {
      $("body").addClass("scrolling");
      $("header").find(".reveal").removeClass("reveal");
    }

    if ($("body").hasClass("single-fasad_object")) {
      var heroImage = new Image();
      heroImage.src = $(".hero img").attr("src");

      heroImage.onload = function () {
        animateHeroLogotype();
      };

      $(".hero video").on("loadeddata", function () {
        animateHeroLogotype();
      });

      function animateHeroLogotype() {
        setTimeout(function () {
          $(".hero").find("svg:first").css("opacity", 1);
        }, 200);

        setTimeout(function () {
          $(".hero").find("svg:first").css("opacity", 0);
          $(".hero-overlay").addClass("bg-transparent");
        }, 3600);
      }

      $(".goto-content").on("click", function (e) {
        e.preventDefault();

        $("body, html").animate(
          {
            scrollTop: $("#wrap")[0].offsetTop - $(".banner").outerHeight(),
          },
          200
        );
      });
    }

    me.onScroll();
  };
  Wrede.prototype.onScroll = function () {
    var me = this;
    var $fasad_object_container = $(".fasad_object-container");
    var $fasad_object_images = $("#fasad-object-images");
    var $facts = $(".facts");
    var $speculator_button = $(".speculator-button");
    var speculator_button_trigger = 0;
    var floorplan_button_trigger = 0;

    if ($facts.length) {
      speculator_button_trigger = $facts.offset().top;
    }
    if ($fasad_object_images.length) {
      floorplan_button_trigger = $(".hero").outerHeight() / 2;
    }
    if (backgroundBreakpoint === 0) {
      backgroundBreakpoint =
        $(".hero").outerHeight() -
        parseInt($(".menu-wrapper").css("top")) -
        $(".menu-button").outerHeight();
    }
    var $currentScrollTop = $(window).scrollTop();
    if ($currentScrollTop > 0) {
      $("body").addClass("scrolling");

      if ($currentScrollTop > backgroundBreakpoint) {
        $(".brand").parent().parent().addClass("black");
        $(".menu-button").addClass("dark");
      } else {
        $(".brand").parent().parent().removeClass("black");
        $(".menu-button").removeClass("dark");
      }
      if ($fasad_object_container.length) {
        if (floorplan_button_trigger > 0) {
          if ($currentScrollTop >= floorplan_button_trigger) {
            $(".hero").find(".floorplan-button").css("opacity", 1);
            $(".hero").find(".floorplan-button").css("visibility", "visible");
            $(".hero")
              .find(".floorplan-button")
              .css("transform", "translate(0, -50%)");
          } else {
            $(".hero").find(".floorplan-button").css("opacity", 0);
            $(".hero").find(".floorplan-button").css("visibility", "hidden");
            $(".hero")
              .find(".floorplan-button")
              .css("transform", "translate(100%, -50%)");
          }
        }
        if (speculator_button_trigger > 0 && $speculator_button.length) {
          if ($currentScrollTop >= speculator_button_trigger) {
            if (!$speculator_button.hasClass("hidden")) {
              $(".speculator-button:not(.hidden)").css("opacity", 1);
              $(".speculator-button:not(.hidden)").css("visibility", "visible");
              setTimeout(function () {
                $speculator_button.addClass("hidden");
              }, 10000);
            }
          }
        }
      }
    } else {
      $("body").removeClass("scrolling");
    }
  };
  Wrede.prototype.onResize = function () {
    var me = this;
    var $wpadminbar = $("#wpadminbar");
    if ($wpadminbar.length) {
      $("header .container.fixed").css("top", $wpadminbar.outerHeight() + "px");
    }
    me.fixWrapper();
  };
  Wrede.prototype.initObjectFilters = function () {
    var searchParams = new URLSearchParams(window.location.search);
    var priceMinFilter = document.getElementById("fasad-min-price");
    var priceMin = priceMinFilter ? priceMinFilter.value : 0;
    var priceMaxFilter = document.getElementById("fasad-max-price");
    var priceMax = priceMaxFilter ? priceMaxFilter.value : 0;
    var priceFilterSubmit = document.querySelector(".fasad-price-submit");
    var districtFilters = document.querySelectorAll('[name="fasad-district"]');

    var livingAreaMin = document.getElementById("fasad-min-living-area");
    var livingAreaMax = document.getElementById("fasad-max-living-area");

    var roomsMin = document.getElementById("fasad-min-rooms");
    var roomsMax = document.getElementById("fasad-max-rooms");

    var areaFilterSubmit = document.querySelector(".fasad-area-submit");

    var filteredDistricts = [];
    var filteredDistrict = "";

    $(document).on(
      "click.bs.dropdown.data-api",
      ".fasad-objects-filter",
      function (e) {
        e.stopPropagation();
      }
    );

    if (searchParams) {
      if (districtFilters.length) {
        for (var i = 0; i < districtFilters.length; i++) {
          const objectsFilter = districtFilters[i];
          const searchQuery = searchParams.has("district")
            ? searchParams.get("district").toLowerCase()
            : false;
          filteredDistrict = objectsFilter.id;

          if (searchQuery && searchQuery == filteredDistrict) {
            filteredDistricts.push(searchParams.get("district"));
            objectsFilter.checked = true;
          }
        }

        if (filteredDistricts.length) {
          filterMuuri();
        }
      }
    }

    if (roomsMin) {
      roomsMin.addEventListener("change", function (e) {
        filterMuuri();
      });
    }

    if (roomsMax) {
      roomsMin.addEventListener("change", function (e) {
        filterMuuri();
      });
    }

    if (areaFilterSubmit) {
      areaFilterSubmit.addEventListener("click", function (e) {
        filterMuuri();
      });
    }

    if (priceMinFilter && priceMaxFilter && priceFilterSubmit) {
      if (parseInt(priceMaxFilter.value) > parseInt(priceMax)) {
        priceMaxFilter.value = priceMax;
      } else if (parseInt(priceMaxFilter.value) < parseInt(priceMax)) {
        priceMaxFilter.value = priceMin;
      }

      if (parseInt(priceMinFilter.value) < parseInt(priceMin)) {
        priceMinFilter.value = priceMin;
      } else if (parseInt(priceMinFilter.value) > parseInt(priceMin)) {
        priceMinFilter.value = priceMax;
      }

      if (parseInt(priceMaxFilter.value) <= parseInt(priceMinFilter.value)) {
        priceMaxFilter.value = priceMinFilter.value;
      }

      if (parseInt(priceMinFilter.value) >= parseInt(priceMaxFilter.value)) {
        priceMinFilter.value = priceMaxFilter.value;
      }

      priceFilterSubmit.addEventListener("click", function (e) {
        filterMuuri();
      });
    }

    if (districtFilters.length) {
      for (var i = 0; i < districtFilters.length; i++) {
        districtFilters[i].addEventListener("change", function (e) {
          var target = e.target;
          var objectType = target.dataset.objectType;

          filteredDistrict = target.id;

          if (target.checked) {
            if (objectType == "district") {
              filteredDistricts.push(filteredDistrict);
            }
          } else {
            var districtIndex = filteredDistricts.indexOf(filteredDistrict);

            if (districtIndex !== -1) {
              if (objectType == "district") {
                filteredDistricts.splice(districtIndex, 1);
              }
            }
          }

          filterMuuri();
        });
      }
    }

    function filterMuuri() {
      muuriGrid.filter(function (item) {
        var objectPrice = item.getElement().dataset.price;
        var livingArea = item.getElement().dataset.livingArea;

        var matchingArea =
          !livingArea ||
          (parseInt(livingArea) >= livingAreaMin.value &&
            parseInt(livingArea) <= livingAreaMax.value);

        var matchingMinPrice =
          !objectPrice ||
          (objectPrice &&
            priceMinFilter &&
            parseInt(objectPrice) >= parseInt(priceMinFilter.value));

        var matchingMaxPrice =
          !objectPrice ||
          (objectPrice &&
            priceMaxFilter &&
            parseInt(objectPrice) <= parseInt(priceMaxFilter.value));

        var matchingDistricts = filteredDistricts.some(function (district) {
          return item.getElement().dataset.district.indexOf(district) >= 0;
        });

        if (filteredDistricts.length == 0) {
          matchingDistricts = true;
        }

        if (!objectPrice) {
          matchingMinPrice = true;
          matchingMaxPrice = true;
        }

        if (livingArea.length == 0) {
          matchingArea = true;
        }

        console.log("price == ", objectPrice);

        console.log(
          "matchingArea == " + matchingArea + "\r\n",
          "matching MinPrice ==" + matchingMinPrice + "\r\n",
          "matching MaxPrice ==" + matchingMaxPrice + "\r\n",
          "matching district ==" + matchingDistricts + "\r\n"
        );

        if (
          matchingMinPrice &&
          matchingMaxPrice &&
          matchingArea &&
          matchingDistricts
        ) {
          return true;
        }

        return false;
      });
    }
  };
  Wrede.prototype.initObjectsCarousel = function () {
    var objectsCarousel = document.querySelectorAll(
      ".fasad-list-item-wrapper.carousel"
    );

    if (objectsCarousel.length) {
      objectsCarousel.forEach(function (element) {
        var swiper = new Swiper(element, {
          grabCursor: true,
          roundLengths: true,
          slideClass: "fasad_object",
          pagination: {
            el: ".swiper-pagination",
          },
          slidesPerView: 1,
          autoplay: {
            delay: 1800,
          },
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
          },
          rounding: true,
          speed: 900,
        });
      });
    }
  };
  Wrede.prototype.initCardsCarousel = function () {
    var objectsCarousel = document.querySelectorAll(".block-cards .carousel");
    var windowWidth = $(window).width();

    if (objectsCarousel.length) {
      objectsCarousel.forEach(function (element) {
        var swiper = new Swiper(element, {
          init: false,
          grabCursor: true,
          roundLengths: true,
          slidesPerView: "auto",
          pagination: {
            el: ".swiper-pagination",
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });

        if (windowWidth > 991) {
          swiper.init();
        }
      });
    }

    // Adjust content height of cards
    var contentBoxes = document.querySelectorAll(
      ".block-cards .carousel .body"
    );

    var highest = 0;

    for (var i = 0; i < contentBoxes.length; i++) {
      var x = contentBoxes[i];
      highest = Math.max(highest, x.offsetHeight);
    }

    for (var i = 0; i < contentBoxes.length; i++) {
      var x = contentBoxes[i];
      x.style.height = highest + "px";
    }
  };
  Wrede.prototype.initLogotype = function () {
    var $logotype = $(".brand").find("svg");
    var $heroLogotype = $(".fasad-hero").find("svg");
    var animationDuration = 0;
    var animationStagger = 0;
    var animationTimeOut = 0;

    $logotype.parent().addClass("opacity-100");
    $heroLogotype.css("opacity", 1);

    if (!$("body").hasClass("single-fasad_object")) {
      animationDuration = 500;
      animationStagger = 400;
      animationTimeOut = 200;
    }

    var drawSvg = $logotype.drawsvg({
      duration: animationDuration,
      stagger: animationStagger,
    });

    var drawHeroSvg = $heroLogotype.drawsvg({
      duration: 500,
      stagger: 400,
    });

    setTimeout(function () {
      drawSvg.drawsvg("animate");
    }, animationTimeOut);

    setTimeout(function () {
      drawHeroSvg.drawsvg("animate");
    }, 200);
  };
  Wrede.prototype.initBlocks = function () {
    var $document = $(document);
    var $main = $(".main");
    var $hero = $(".block-hero");
    var $heroArrow = $hero.find(".arrow");
    var scrollTop = $("document").scrollTop();

    if (scrollTop > 0) {
      $main.removeClass("nomargin-top");
    }

    $document.on("scroll", function () {
      scrollTop = $document.scrollTop();

      if ($main.hasClass("nomargin-top") && scrollTop > 0) {
        $main.removeClass("nomargin-top");
        $heroArrow.css("opacity", 0);
      } else if (scrollTop === 0) {
        $heroArrow.css("opacity", 1);
        $main.addClass("nomargin-top");
      }
    });

    if ($hero.length && $hero.hasClass("show-objects")) {
      var carousel = new Swiper($hero.find(".carousel").get(0), {
        roundLengths: true,
        effect: "fade",
        loop: true,
        followFinger: false,
        autoplay: {
          delay: 5000,
        },
        fadeEffect: {
          crossFade: true,
        },
        speed: 1500,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }
  };
  Wrede.prototype.initListCarousel = function () {
    var listCarousel = document.querySelectorAll(".list-carousel");

    if (listCarousel.length) {
      listCarousel.forEach(function (element) {
        var swiper = new Swiper(element, {
          lazy: true,
          grabCursor: true,
          roundLengths: true,
          slidesPerView: 1,
          effect: "fade",
          loop: true,
          fadeEffect: {
            crossFade: true,
          },
          pagination: {
            el: element.querySelector(".swiper-pagination"),
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      });
    }
  };
})(jQuery, window);
